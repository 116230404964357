import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from './useAuth';
import {useUserFullName, useCase, useCaseUpsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {CaseForm} from './CaseForm';

export const ApproveCollaborationScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const userId = useAuth().userId;
  const userFullName = useUserFullName(userId);
  const caseId = props.caseId || params.caseId;
  const caseValues = useCase(caseId);
  const mutation = useCaseUpsertMutation(caseId);

  useEffect(() => {
    if (mutation.isSuccess) {
      props.onClose();
    }
  }, [mutation.isSuccess]);

  const submit = (formValues) => {
    const updated = {
      ...caseValues,
      CollaborationStatus: formValues.CollaborationStatus
    };
    switch (formValues.CollaborationStatus) {
      case 'Approved':
      case 'Reqested':
        break;
      default:
        updated.CollaborationStatus = '';
        updated.id_Instructor_Collaborator = '';
        updated.PT_PrescribingPhysician = '';
        break;
    }
    mutation.mutate(updated);
  };

  const cancel = () => {
    props.onClose();
  };

  return (
    <div className="ApproveCollaborationScreen">
      <Headline>{t('ApproveCollaborationScreen.headline')}</Headline>
      <CaseForm i18nScopes={['ApproveCollaborationScreen']} values={caseValues} onSubmit={submit} onCancel={cancel} mutation={mutation} admin={true}/>
    </div>
  );
};

export default ApproveCollaborationScreen;
