import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useCase} from './useApi';
import {Fragment} from 'react';
import {Headline} from './Headline';
import {CaseTable} from './CaseTable';
import {DashboardIcons} from './DashboardIcons';
import {IconButton} from './IconButton';
import caseInfoIcon from './images/paperwithhandpointing.png';
import measurementIcon from './images/measuringtape.png';
import workspaceIcon from './images/cubewithaxes.png';
import resourcesIcon from './images/pencil.png';

export const CaseDashboardIcons = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const caseValues = useCase(props.caseId);

  const buttons = [
    {
      screen: 'caseInformation',
      icon: caseInfoIcon,
      path: '/' + props.caseId,
    },
    {
      screen: 'measurements',
      icon: measurementIcon,
      path: '/' + props.caseId,
    },
    {
      screen: 'workspace',
      icon: workspaceIcon,
      path: '/' + props.caseId,
    },
    {
      screen: 'resources',
      icon: resourcesIcon,
      path: '/' + props.caseId,
    },
  ];

  return (
    <Fragment>
      <Headline>{t('CaseDashboardIcons.headline')}</Headline>
      <CaseTable cases={[caseValues]} include={[
        'PT_FirstName',
        'PT_LastName',
        'CaseReference',
        'PT_TreatingClinician',
      ]}/>
      <DashboardIcons>
        {buttons.map((b, i) => (
          <IconButton key={i} src={b.icon} alt={t('CaseDashboardIcons.' + b.screen + 'Alt')} onClick={() => history.push('/' + b.screen + (b.path || ''))}>
            {t('CaseDashboardIcons.' + b.screen)}
          </IconButton>
        ))}
      </DashboardIcons>
    </Fragment>
  );
};

export default CaseDashboardIcons;
