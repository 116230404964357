import './Spinner.css';

export const Spinner = () => {
  return (
    <div className="Spinner">
      <svg className="circularProgressIndicator circularProgressIndicator-active">
        <circle className="circularProgress-animatedPath" style={{stroke: '#fda200'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
      </svg>
    </div>
  );
};

export default Spinner;
