import {Children} from 'react';
import './IconButton.css';

export const IconButton = (props) => (
  <div className={'IconButton unselectable ' + (props.className || '')}>
    <button onClick={props.onClick}>
      <div className="IconButtonIcon">
        <img className="undraggable" src={props.src} alt={props.alt}/>
      </div>
      {Children.count(props.children) > 0 && (
        <div className="IconButtonText">
          <div className="baseFont">
            <div>{props.children}</div>
          </div>
        </div>
      )}
    </button>
  </div>
);

export default IconButton;
