import {useAuth} from './useAuth.js'
import {useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Appbar} from './Appbar';
import {Menu, MenuItem, MenuSpacer} from './Menu';
import {IconButton} from './IconButton';
import {Image} from './Image';
import menuIcon from './images/menu.png';
import helpIcon from './images/help.png';
import homeIcon from './images/home.png';
import myFilesIcon from './images/myFiles.png';
import backIcon from './images/back.png';
import './AppScreen.css';

export const AppScreen = (props) => {
  const auth = useAuth();
  const history = useHistory();
  const {t} = useTranslation();
  return (
    <div className={'AppScreen ' + (props.className || '')}>
      <div className="AppScreenArea">
          <div className="AppScreenViewport">
            <div className="AppScreenContent">
              {props.children}
            </div>
          </div>
          {props.modal}
      </div>
      {auth.userId && (
        <Appbar>
          <IconButton src={backIcon} alt={t('Appbar.backAlt')} onClick={() => history.goBack()}/>
          <IconButton src={myFilesIcon} alt={t('Appbar.myCasesAlt')} onClick={() => history.push('/myCases')}/>
          <IconButton src={homeIcon} alt={t('Appbar.homeAlt')} onClick={() => history.push(props.home || '/')}/>
          <IconButton src={helpIcon} alt={t('Appbar.helpAlt')} onClick={() => history.push('/help')}/>
          <Menu label={<img src={menuIcon}/>} alt={t('Appbar.menuAlt')}>
            <MenuItem onClick={() => history.push('/logout')}>Log out</MenuItem>
            <MenuSpacer/>
            <MenuItem onClick={() => history.push('/profile')}>My Profile</MenuItem>
          </Menu>
        </Appbar>
      )}
    </div>
  );
}

export default AppScreen;
