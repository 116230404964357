import util from 'util';
import {useTranslation} from 'react-i18next';
import {createContext, useContext, Children, useEffect, useState, useMemo, useCallback, useReducer, useRef, Fragment} from 'react';
import {Prompt} from './Prompt';
import {Button} from './Button';
import {Spinner} from './Spinner';
import {useKeyCode} from './useKeyCode';
import './Form.css';

const FormContext = createContext();

export const useI18nScopes = () => useContext(FormContext).i18nScopes;

const newFieldState = ({originalValue, savedValue, value, defaultValue, changedByUser, unmergedValue}) => ({
  originalValue: originalValue || savedValue || '',
  savedValue: savedValue || '',
  value: value || savedValue || defaultValue || '',
  changedByUser: !!changedByUser,
  unmergedValue,
});

const takeUpdate = (value, update) => update;

const mergeUpdates = (values, updates, mergeUpdate = takeUpdate) => {
  const mergedUpdates = {};
  for (const [name, update] of Object.entries(updates)) {
    const value = values[name];
    const updatedValue = mergeUpdate(value, update);
    if (!Object.is(value, updatedValue)) {
      mergedUpdates[name] = updatedValue;
    }
  }
  if (Object.keys(mergedUpdates).length === 0) {
    return values;
  }
  return {...values, ...mergedUpdates};
};

const mergeValuesChange = (field, {savedValue, value}) => {
  const savedValueChanged = savedValue !== undefined && !Object.is(field.savedValue, savedValue);
  const valueChanged = value !== undefined && !Object.is(field.value, value);
  if (savedValueChanged) {
    if (valueChanged) {
      const updatedField = {...field, savedValue, value};
      delete updatedField.changedByUser;
      delete updatedField.unmergedValue;
      return updatedField;
    } else {
      const updatedField = {...field, savedValue};
      if (field.changedByUser) {
        updatedField.unmergedValue = savedValue;
      } else {
        updatedField.value = savedValue;
      }
      return updatedField;
    }
  } else if (valueChanged) {
    const updatedField = {...field, value};
    delete updatedField.changedByUser;
    return updatedField;
  }
  return field;
};

const mergeValueChangeByUser = (field, value) => {
  if (field.changedByUser && Object.is(field.value, value)) {
    return field;
  }
  return {...field, value, changedByUser: true};
};

const mergeFieldUpdates = (state, updates, mergeUpdate = takeUpdate) => (
  mergeUpdates(state, {fields: mergeUpdates(state.fields, updates, mergeUpdate)})
);

const mergeFieldValueUpdates = (state, {savedValues, values}) => {
  const updates = {};
  const mergeUpdates = (key, values) => {
    if (values) {
      for (const [name, value] of Object.entries(values)) {
        if (state.fields[name]) {
          updates[name] = {...(updates[name] || {}), [key]: value};
        }
      }
    }
  }
  mergeUpdates('savedValue', savedValues);
  mergeUpdates('value', values);
  return mergeFieldUpdates(state, updates, mergeValuesChange);
};

const recalculateDerivedState = (state) => {
  const userChangedValues = {};
  const mergedValues = {};
  const unmergedValues = {};
  const updatedValues = {};
  for (const [name, field] of Object.entries(state.fields)) {
    if (field.changedByUser && !Object.is(field.value, field.savedValue)) {
      userChangedValues[name] = field.value;
    }
    if (field.unmergedValue) {
      unmergedValues[name] = field.value;
    } else if (field.savedValue !== field.originalValue) {
      mergedValues[name] = field.value;
    }
    if (!Object.is(field.value, state.values[name])) {
      updatedValues[name] = field.value;
    }
  }
  return mergeUpdates(state, {
    userChangedValues: Object.keys(userChangedValues).length !== 0 ? userChangedValues : null,
    mergedValues: Object.keys(mergedValues).length !== 0 ? mergedValues : null,
    unmergedValues: Object.keys(unmergedValues).length !== 0 ? unmergedValues : null,
    values: mergeUpdates(state.values, updatedValues),
  });
};

const actionReducers = {
  fieldsRegistered: (state, action) => mergeUpdates(mergeFieldUpdates(state, action.fields), {recalculationDelay: 0}),
  valuesChanged: (state, action) => mergeFieldValueUpdates(state, action),
  fieldValuesChanged: (state, action) => mergeFieldUpdates(state, action.values, mergeValuesChange),
  valuesChangedByUser: (state, action) => mergeFieldUpdates(state, action.values, mergeValueChangeByUser),
  recalculationDelayExpired: (state, action) => mergeUpdates(recalculateDerivedState(state), {recalculationDelay: 50}),
};

const formStateReducer = (state, action) => (
  mergeUpdates(state, actionReducers[action.name](state, action))
);

const fieldActionTranslators = {
  valuesChanged: (fieldName, action) => ({name: 'fieldValuesChanged', values: {[fieldName]: {savedValue: action.savedValue, value: action.value}}}),
  valueChangedByUser: (fieldName, action) => ({name: 'valuesChangedByUser', values: {[fieldName]: action.value}}),
};

export const useFieldReducer = ({name, ...props}) => {
  const {formProps, fieldProps, renderOrder, state, dispatch} = useContext(FormContext);
  fieldProps[name] = props;
  renderOrder.push(name);
  const fieldState = state.fields[name] || (() => (newFieldState({
    value: (formProps.values || {})[name],
    savedValue: (formProps.savedValues || {})[name],
    defaultValue: (formProps.defaultValues || {})[name],
    ...props,
  })))();
  useEffect(() => {
    dispatch({name:'fieldsRegistered', fields: {[name]: fieldState}});
  }, []);
  return [fieldState, action => dispatch(fieldActionTranslators[action.name](name, action))];
};

const FormBody = (props) => {
  const {t} = useTranslation();
  const {
    i18nScopes,
    fieldOrder,
    fieldProps,
    state,
    dispatch,
    submitRef,
  } = useContext(FormContext);

  const checkValidity = (values) => {
    for (let field of Object.values(fieldProps)) {
      if (field.checkValidity && !field.checkValidity()) {
        return false;
      }
    }
    return !props.checkValidity || props.checkValidity(values);
  };

  const reportValidity = (values) => {
    for (let field of Object.values(fieldProps)) {
      if (field.reportValidity && field.checkValidity && !field.checkValidity()) {
        field.reportValidity();
        return;
      }
    }
    if (props.reportValidity && props.checkValidity && !props.checkValidity(values)) {
      props.reportValidity(values);
      return;
    }
  };

  const submit = useCallback(() => {

    // Recalculate in case submit() was called before the delayed update.
    const {
      values,
      mergedValues,
      unmergedValues,
    } = recalculateDerivedState(state);

    // Ensure that any changes are reported before calling checkValidity, reportValidity, or onSubmit, in case the parent expects it
    if (props.onChange) {
      props.onChange(values);
    }

    if (!checkValidity(values)) {
      reportValidity(values);
      return;
    }
    if (mergedValues && !window.confirm(t(i18nScopes.map(s => s + '.hasMergedUpdatePrompt')))) {
      return;
    }
    if (unmergedValues && !window.confirm(t(i18nScopes.map(s => s + '.hasUnmergedUpdatePrompt')))) {
      return;
    }

    props.onSubmit(values);

  }, [state, props.onChange, props.checkValidity, props.reportValidity, props.onSubmit]);

  const cancel = useCallback(() => {
    if (props.onCancel) {
      props.onCancel();
    }
  }, [props.onCancel]);

  useKeyCode(false, useCallback(k => {
    switch (k) {
      case 27: // escape
        cancel();
        break;
      case 37: // left arrow
        break;
      case 38: // up arrow
        break;
      case 39: // right arrow
        break;
      case 40: // down arrow
        break;
    }
  }, [cancel]));

  return (
    <Fragment>
      <div className={'Form ' + (props.className || '')}>
        {props.children}
        <div className="FormButtons">
          {props.onCancel ? <Button className="FormCancelButton" onClick={cancel}>{t(i18nScopes.map(s => s + '.cancelButton'))}</Button> : null}
          <Button ref={submitRef} className="FormSubmitButton" onClick={submit}>{t(i18nScopes.map(s => s + '.submitButton'))}</Button>
          <Prompt when={state.userChangedValues && (!props.mutation || !props.mutation.isSuccess)} message={() => {
            submitRef.current.focus();
            return t(i18nScopes.map(s => s + '.hasUserChangePrompt'));
          }}/>
        </div>
      </div>
      {props.mutation && props.mutation.isLoading && <Spinner/>}
    </Fragment>
  );
};

export const Form = (props) => {

  const i18nScopes = [...(props.i18nScopes || []), 'Form'];
  const renderOrder = [];
  const fieldOrder = props.fieldOrder || renderOrder;
  const fieldProps = {};
  const [state, dispatch] = useReducer(formStateReducer, {
    fields: {},
    values: {},
    userChangedValues: null,
    mergedValues: null,
    unmergedValues: null,
    recalculationDelay: 0,
  });
  const [lastValidFocusedColumn, setLastValidFocusedColumn] = useState(0);
  const submitRef = useRef();

  useEffect(() => {
    dispatch({
      name: 'valuesChanged',
      values: props.values,
      savedValues: props.savedValues,
    });
  }, [props.savedValues, props.values]);

  useEffect(() => {
    const recalculate = () => dispatch({name: 'recalculationDelayExpired'});
    if (state.recalculationDelay == 0) {
      recalculate();
    } else {
      const timeoutId = setTimeout(recalculate, state.recalculationDelay);
      return () => clearTimeout(timeoutId);
    }
  }, [state.recalculationDelay, state.fields]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state.values);
    }
  }, [state.values]);

  const findCurrentFieldOrderCoordinates = (name) => {
    for (let r = 0; r < fieldOrder.length; r++) {
      const row = fieldOrder[r];
      if (Array.isArray(row)) {
        for (let c = 0; c < row.length; c++) {
          if (row[c] == name) {
            setLastValidFocusedColumn(c);
            return [r, c];
          }
        }
      } else if (fieldOrder[r] == name) {
        return [r, lastValidFocusedColumn];
      }
    }
    return [-1, null];
  }

  const nextField = (r, c) => {
    r++;
    if (r >= fieldOrder.length || !Array.isArray(fieldOrder[r])) {
      let s = r;
      while (s >= 1 && Array.isArray(fieldOrder[s-1])) {
        s--;
      }
      let d = c + 1;
      for (; s < r; s++) {
        const row = fieldOrder[s];
        if (d < row.length) {
          return [row[d], s, d];
        }
      }
    }
    if (r < fieldOrder.length) {
      const row = fieldOrder[r];
      return [Array.isArray(row) ? (row[c < row.length ? c : row.length - 1]) : row, r, c];
    }
    return [null, r, c];
  };

  const formContext = {
    i18nScopes,
    formProps: props,
    submitRef,
    renderOrder,
    fieldOrder,
    fieldProps,
    state,
    dispatch,
    focusNext: (previousName) => {
      let [r, c] = findCurrentFieldOrderCoordinates(previousName);
      let f;
      for (;;) {
        [f, r, c] = nextField(r, c);
        if (f == null) {
          submitRef.current.focus();
          break;
        } else {
          const field = fieldProps[f];
          if (!field.disabled) {
            field.focus();
            break;
          }
        }
      }
    },
  };

  return (
    <FormContext.Provider value={formContext}>
      <FormBody {...props}>{props.children}</FormBody>
    </FormContext.Provider>
  );
};

export const useFocusNext = (name) => {
  const {focusNext} = useContext(FormContext);
  return () => focusNext(name);
};

export default Form;
