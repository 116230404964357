import {useTranslation} from 'react-i18next';
import {useAuth} from './useAuth.js'
import {Redirect} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Title} from './Title';
import {Logo} from './Logo';
import {Subtitle} from './Subtitle';
import {Button} from './Button';
import './LoggedOutScreen.css'

export const LoggedOutScreen = () => {
  const {t} = useTranslation();
  const auth = useAuth();
  return (
    <AppScreen className="LoggedOutScreen">
      <Title>{t('LoggedOutScreen.title')}</Title>
      <Subtitle>{t('LoggedOutScreen.subtitle')}</Subtitle>
      <Logo/>
      <Button onClick={() => auth.initiateLogin()}>{t('LoggedOutScreen.loginButton')}</Button>
    </AppScreen>
  );
}

export default LoggedOutScreen;
