import {useTranslation} from 'react-i18next';
import {Title} from './Title'
import {Button} from './Button'
import './ErrorFallback.css';

export const ErrorFallback = function ({error, resetErrorBoundary}) {
  const {t} = useTranslation();
  return (
    <div className="ErrorFallback" role="alert">
      <Title>{t('ErrorFallback.title', 'Something went wrong')}</Title>
      <pre>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>{t('ErrorFallback.retryButton', 'Retry')}</Button>
    </div>
  );
}

export default ErrorFallback;
