import './Headline.css';

export const Headline = (props) => (
  <div className={'Headline ' + (props.className || '')}>
    <div className="headlineFont unselectable">
      <div>{props.children}</div>
    </div>
  </div>
);

export default Headline;
