import {useTranslation} from 'react-i18next';
import {DataTable} from './DataTable';

export const CaseTable = (props) => {
  const {t} = useTranslation();
  const cases = props.cases;

  const columns = [
    {
      accessor: 'PT_FirstName',
    },
    {
      accessor: 'PT_LastName',
    },
    {
      accessor: 'PT_Country',
    },
    {
      accessor: 'PT_Diagnosis',
    },
    {
      accessor: 'PT_Laterality',
      Cell: ({value}) => value ? t('lateralityName.' + value, value) : '',
    },
    {
      accessor: 'PT_Intervention',
      Cell: ({value}) => value ? t('interventionName.' + value, value) : '',
    },
    {
      accessor: 'PT_DateofOnset',
    },
    {
      accessor: 'PT_EvalScanDate',
    },
    {
      accessor: 'PT_CaseNotes',
    },
    {
      accessor: 'CaseReference',
    },
    {
      accessor: 'PT_TreatingClinician',
    },
    {
      accessor: 'PT_PrescribingPhysician',
    },
    {
      accessor: 'CollaborationStatus',
      Cell: ({value}) => value ? t('collaborationStatusName.' + value, value) : '',
    },
  ].filter(c => (!props.include || props.include.includes(c.id || c.accessor))
           && (!props.exclude || !props.exclude.includes(c.id || c.accessor)));

  return (
    <DataTable
      i18nScopes={[...(props.i18nScopes || []), 'CaseTable', 'Case']}
      columns={columns}
      data={cases}
      onRowClicked={props.onRowClicked}
    />
  );
};

export default CaseTable;
