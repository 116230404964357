import React, { useEffect } from 'react';

export const useKeyCode = (capture, onKeyCode) => {
  useEffect(() => {
    window.addEventListener('keydown', onKeyCode, !!capture);
    return () => {
      window.removeEventListener('keydown', onKeyCode, !!capture);
    };
  }, [onKeyCode, capture]);
};

export default useKeyCode;
