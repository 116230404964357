import {createContext} from 'react';

export const WorkspaceContext = createContext();

export const WorkspaceContextProvider = ({children}) => (
  <WorkspaceContext.Provider value={{
    workspaceDestroyed: false,
  }}>
    {children}
  </WorkspaceContext.Provider>
);

export default WorkspaceContext;
