import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Fragment} from 'react';
import {Headline} from './Headline';
import {DashboardIcons} from './DashboardIcons';
import {IconButton} from './IconButton';
import driveIcon from './images/googleDrive.png';
import onIcon from './images/onicon.png';
import {openInNewTab} from './openInNewTab';

export const ResourcesDashboardIcons = (props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const buttons = [
    {
      screen: 'linkGBDrive',
      icon: driveIcon,
      onClick: () => openInNewTab("https://drive.google.com/drive/folders/1y_B7kKhft67z3Shn8_AcLcvKYsJPtHoo"),
    },
    {
      screen: 'linkONDrive',
      icon: onIcon,
      onClick: () => openInNewTab("https://drive.google.com/drive/folders/1Zgvl-fs_yLWwHqIh-xUWNpb8x2nL1vuC?usp=sharing"),
    },
  ];

  return (
    <Fragment>
      <Headline>{t('ResourcesDashboardIcons.headline')}</Headline>
      <DashboardIcons>
        {buttons.map((b, i) => (
          <IconButton key={i} src={b.icon} alt={t('ResourcesDashboardIcons.' + b.screen + 'Alt')} onClick={b.onClick || (() => history.push('/' + b.screen + (b.path || '')))}>
            {t('ResourcesDashboardIcons.' + b.screen)}
          </IconButton>
        ))}
      </DashboardIcons>
    </Fragment>
  );
};

export default ResourcesDashboardIcons;
