import {useTranslation} from 'react-i18next';
import {useContext, useState, useEffect, useRef} from 'react';
import MUIInput from 'muicss/lib/react/input';
import {useI18nScopes, useFieldReducer, useFocusNext} from './Form';
import './Input.css';

export const Input = (props) => {
  const i18nScopes = useI18nScopes().map(s => s + '.' + props.name);
  const {t} = useTranslation();

  const ref = useRef();
  const focusNext = useFocusNext(props.name);

  const [state, dispatch] = useFieldReducer({
    ...props,
    checkValidity: () => ref.current.controlEl.checkValidity(),
    reportValidity: () => ref.current.controlEl.reportValidity(),
    focus: () => {
      ref.current.controlEl.focus();
      ref.current.controlEl.select();
    },
  });

  useEffect(() => {
    dispatch({
      name: 'valuesChanged',
      savedValue: props.savedValue,
      value: props.value,
    });
  }, [props.savedValue, props.value]);

  const onChange = (e) => {
    dispatch({
      name: 'valueChangedByUser',
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state.value);
    }
  }, [state.value]);

  useEffect(() => {
    const onKeyPress = (e) => {
      switch (e.keyCode) {
        case 13:
          e.preventDefault();
          focusNext();
          break;
/*
        case 37: // left arrow
          formContext.focusNextLeft(props.name);
          break;
        case 38: // up arrow
          formContext.focusNextUp(props.name);
          break;
        case 39: // right arrow
          formContext.focusNextRight(props.name);
          break;
        case 40: // down arrow
          formContext.focusNextDown(props.name);
          break;
*/
      }
    };
    const el = ref.current.controlEl;
    el.addEventListener('keypress', onKeyPress, true);
    return () => el.removeEventListener('keypress', onKeyPress, true);
  });

  const muiInputProps = {
    name: props.name,
    type: props.type,
    value: state.value || '',
    required: props.required,
    disabled: props.disabled,
    ref,
    onChange,
  };

  if (props.placeholder !== false) {
    if (props.placeholder !== undefined) {
      muiInputProps.placeholder = props.placeholder;
    } else {
      muiInputProps.placeholder = t([...i18nScopes.map(s => s + '.placeholder'), 'Input.placeholder']);
    }
  }
  if (props.label !== false) {
    muiInputProps.label = props.label || t([].concat(...i18nScopes.map(s => [s + '.label', s]))) + (props.required ? ' *' : '');
  }

  return (
    <div className={'Input ' + (props.className || '')}>
      <MUIInput {...muiInputProps}/>
    </div>
  );
};

export default Input;
