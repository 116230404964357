import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Fragment} from 'react';
import {Headline} from './Headline';
import {DashboardIcons} from './DashboardIcons';
import {IconButton} from './IconButton';
import {useAuth} from './useAuth';
import {useCases} from './useApi';
import addCaseIcon from './images/folderwithplus.png';
import myCasesIcon from './images/folderabovehand.png';
import resourcesIcon from './images/pencil.png';

export const StudentDashboardIcons = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const userId = useAuth().userId;
  const cases = useCases({id_Student_Treating: userId});

  const buttons = [
    {
      screen: 'addCase',
      icon: addCaseIcon,
    },
  ];

  if (cases && cases.length > 0) {
    buttons.push(...[
      {
        screen: 'myCases',
        icon: myCasesIcon,
      },
      {
        screen: 'resources',
        icon: resourcesIcon
      },
    ]);
  }

  return (
    <Fragment>
      <Headline>{t('StudentDashboardIcons.headline')}</Headline>
      <DashboardIcons>
        {buttons.map((b, i) => (
          <IconButton key={i} src={b.icon} alt={t('StudentDashboardIcons.' + b.screen + 'Alt')} onClick={() => history.push('/' + b.screen)}>
            {t('StudentDashboardIcons.' + b.screen)}
          </IconButton>
        ))}
      </DashboardIcons>
    </Fragment>
  );
};

export default StudentDashboardIcons;
