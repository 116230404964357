import ReactDOM from 'react-dom';
import {App} from './App';
import {Suspense, useState, useEffect, useContext} from 'react';
import {Spinner} from './Spinner';
import {ErrorBoundary} from 'react-error-boundary';
import {ErrorFallback} from './ErrorFallback';
import {QueryClient, QueryClientProvider} from 'react-query';
import {AuthProvider} from './useAuth';
import {BrowserRouter} from 'react-router-dom';
import {WorkspaceContext} from './WorkspaceContext';
import 'muicss/dist/css/mui.min.css';
import './index.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

export const Top = ({mockMsal}) => {
  const [workspaceDestroyed, setWorkspaceDestroyed] = useState(false);
  const [workspaceDestructionChecked, setWorkspaceDestructionChecked] = useState(false);

  const workspaceContext = useContext(WorkspaceContext);
  workspaceContext.workspaceDestroyed = () => {
    setWorkspaceDestroyed(true);
  };

  useEffect(() => {
    if (workspaceDestroyed) {
      window.location.reload();
    }
    setWorkspaceDestructionChecked(true);
  }, [workspaceDestroyed, workspaceDestructionChecked]);
  return (
    (workspaceDestroyed || !workspaceDestructionChecked) ? <Spinner/> :
    <Suspense fallback={<Spinner/>}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AuthProvider mockMsal={mockMsal}>
              <App/>
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </ErrorBoundary>
    </Suspense>
  );
};
