import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useCase, useCaseUpsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {CaseForm} from './CaseForm';

export const CaseInformationScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const caseValues = useCase(params.caseId);
  const mutation = useCaseUpsertMutation(params.caseId);

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push('/caseDashboard/' + mutation.data.id);
    }
  }, [mutation.isSuccess]);

  const submit = (formValues) => {
    mutation.mutate({
      ...caseValues,
      ...formValues
    });
  };

  return (
    <AppScreen className="CaseInformationScreen" home={'/caseDashboard/' + params.caseId}>
      <Logo/>
      <Headline>{t('CaseInformationScreen.headline')}</Headline>
      <CaseForm values={caseValues} onSubmit={submit} mutation={mutation}/>
    </AppScreen>
  );
};

export default CaseInformationScreen;
