import Dropdown from 'muicss/lib/react/dropdown';
import DropdownItem from 'muicss/lib/react/dropdown-item';
import './Menu.css';

export const MenuItem = (props) => (
  <DropdownItem className={'MenuItem ' + (props.className || '')} link={props.link} target={props.target} onClick={props.onClick}>
    {props.children}
  </DropdownItem>
);

export const MenuSpacer = (props) => (
  <div className={'MenuSpacer ' + (props.className || '')}/>
);

export const Menu = (props) => (
  <Dropdown className={'Menu ' + (props.className || '')} label={props.label} placement="up" alignment="right">
    {props.children}
  </Dropdown>
);
