import MUIOption from 'muicss/lib/react/option';
import './Option.css';

export const Option = (props) => (
  <MUIOption {...{
      label: props.label || props.value,
      value: props.value,
    }}/>
);

export default Option;
