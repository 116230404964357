import {DashboardScreen} from './DashboardScreen';
import {StudentDashboardIcons} from './StudentDashboardIcons';

export const StudentDashboardScreen = (props) => {
  return (
    <DashboardScreen>
      <StudentDashboardIcons/>
    </DashboardScreen>
  );
};

export default StudentDashboardScreen;
