export const namePrefixes = [
  "",
  "Dr.",
  "Mr.",
  "Mrs.",
  "Miss",
  "Ms.",
];

export default namePrefixes;
