export const roles = [
  "New",
  "Pending",
  "Treating",
  "Collaborating",
  "Admin",
  "Student",
  "Instructor",
];

export default roles;
