import { v4 as uuidv4 } from 'uuid';
import {useQuery, useMutation, useQueryClient} from 'react-query'
import {AuthStatus, useAuth} from './useAuth';
import {URL} from 'url-ponyfill';
import fetch from 'cross-fetch';

const baseUrl = new URL('/api/', window.location.origin);

function isString(x) {
  return typeof x === 'string' || x instanceof String;
}

function splitQueryKey(queryKey) {
  const queryKeyArray = Array.isArray(queryKey) ? queryKey : [queryKey];
  let startOfParams = 0;
  while (startOfParams < queryKeyArray.length && isString(queryKeyArray[startOfParams])) {
    startOfParams++;
  }
  return {
    pathComponents: queryKeyArray.slice(0, startOfParams),
    parameters: queryKeyArray.slice(startOfParams),
  };
}

function buildUrl(queryKey) {
  const {pathComponents, parameters} = splitQueryKey(queryKey);
  const url = new URL(pathComponents.map(encodeURIComponent).join('/'), baseUrl);
  for (const parameterObject of parameters) {
    const sortedParams = Object.entries(parameterObject).sort((a, b) => a[0] < b[0] ? -1 : (a[0] > b[0] ? 1 : 0));
    const urlSearchParams = new URLSearchParams();
    for (const [name, value] of sortedParams) {
      urlSearchParams.append(name, value);
    }
    url.search = '?' + urlSearchParams.toString();
  }
  return url;
}

async function queryApi(auth, queryKey, canonicalize) {
  const url = buildUrl(queryKey);
  const accessToken = await auth.getAccessToken();
  if (!accessToken) {
    throw new Error("Access token not available");
  }

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    },
  });

  if (response.status >= 500) {
    throw new Error(`Server error: ${response.status} ${response.statusText}`);
  }
  if (response.status == 404) {
    return null;
  }
  if (response.status >= 400) {
    throw new Error(`Invalid request: ${response.status} ${response.statusText}`);
  }
  if (!response.ok) {
    throw new Error(`Unexpected fetch error: ${response.status} ${response.statusText}`);
  }

  const result = await response.json();
  return canonicalize ? canonicalize(result) : result;
};

async function postToApi(auth, queryKey, data, canonicalize) {
  const url = buildUrl(queryKey);
  const accessToken = await auth.getAccessToken();
  if (!accessToken) {
    throw new Error("Access token not available");
  }

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      "Authorization": `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response.status >= 500) {
    throw new Error(`Server error: ${response.status} ${response.statusText}`);
  }
  if (response.status >= 400) {
    throw new Error(`Invalid request: ${response.status} ${response.statusText}`);
  }
  if (!response.ok) {
    throw new Error(`Unexpected fetch error: ${response.status} ${response.statusText}`);
  }

  const result = await response.json();
  return canonicalize ? canonicalize(result) : result;
};

const useApiQuery = (queryKey, filter, canonicalize) => {
  const auth = useAuth();
  if (filter && Object.keys(filter).length > 0) {
    queryKey = [...queryKey, filter];
  }
  return useQuery(queryKey, ({queryKey}) => queryApi(auth, queryKey, canonicalize), {
    enabled: auth.status == AuthStatus.LOGGED_IN,
  });
};

const useApiMutation = (queryKey, canonicalize, options) => {
  const auth = useAuth();
  return useMutation(data => postToApi(auth, queryKey, data, canonicalize), options);
};

export const useApiUpsertMutation = (queryKey, canonicalize) => {
  const queryClient = useQueryClient();
  return useApiMutation(queryKey, canonicalize, {
    onSuccess: data => {
      queryClient.invalidateQueries(queryKey[0]);
      queryClient.setQueryData(queryKey, data);
    },
  });
};

const orLoggedInUser = (userId) => {
  const auth = useAuth();
  return userId || auth.userId;
};

const canonicalizeUser = (user) => ({
  ...user,
  Default_Role: user.Default_Role && user.Default_Role !== '' ? user.Default_Role : 'New',
});

export const useUser = (userId) => {
  return useApiQuery(['user', orLoggedInUser(userId)], null, canonicalizeUser).data;
};

export const useUserUpsertMutation = (userId) => {
  return useApiUpsertMutation(['user', orLoggedInUser(userId)], canonicalizeUser);
};

export const useUsers = (filter) => {
  return useApiQuery(['user'], filter, a => a.map(canonicalizeUser)).data;
};

export const useUserFullName = (userId) => {
  const user = useUser(userId);
  const fullName = [];
  if (user.Prefix && user.Prefix.length > 0) {
    fullName.push(user.Prefix);
  }
  if (user.FirstName && user.FirstName.length > 0) {
    fullName.push(user.FirstName);
  }
  if (user.LastName && user.LastName.length > 0) {
    fullName.push(user.LastName);
  }
  return fullName.join(' ');
}

export const useInstructorRole = (userId) => {
  return useApiQuery(['instructor', orLoggedInUser(userId)]).data;
};

export const useInstructorRoleUpsertMutation = (userId) => {
  return useApiUpsertMutation(['instructor', orLoggedInUser(userId)]);
};

export const useCase = (caseId) => {
  return useApiQuery(['case', caseId]).data;
};

export const useCaseUpsertMutation = (caseId) => {
  return useApiUpsertMutation(['case', caseId]);
};

export const useCaseInsertMutation = () => {
  return useCaseUpsertMutation(uuidv4());
};

export const useCases = (filter) => {
  return useApiQuery(['case'], filter).data;
};

export const useTTMeasurements = (caseId) => {
  return useApiQuery(['ttmeasure', caseId]).data;
};

export const useTTMeasurementsUpsertMutation = (caseId) => {
  return useApiUpsertMutation(['ttmeasure', caseId]);
};
