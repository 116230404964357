import {Children} from 'react';
import {Headline} from './Headline';
import {useWindowSize} from './useWindowSize';
import "./DashboardIcons.css";

function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}

export const DashboardIcons = (props) => {
  const children = Children.toArray(props.children);

  const size = useWindowSize();
  const width = size.width < 568 ? (size.width - 60) : (size.width * .6);
  const iconWidth = size.width < 568 ? 110 : 150;
  const maxIconsPerRow = Math.max(1, Math.floor(width / iconWidth));
  const minRows = Math.ceil(children.length / maxIconsPerRow);
  const childrenPerRow = Math.ceil(children.length / minRows);
  return (
    <div className={'DashboardIcons ' + (props.className || '')}>
      {props.headline && (
        <Headline>{props.headline}</Headline>
      )}
      {
        [...chunks(children, childrenPerRow)].map((r, i) => (
          <div className="DashboardIconRow" key={i}>{r}</div>
        ))
      }
    </div>
  );
};

export default DashboardIcons;
