import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Children} from 'react';
import {Form} from './Form';
import {Input} from './Input';
import {Select} from './Select';
import {Option} from './Option';
import {useUser, useUserUpsertMutation} from './useApi';
import {namePrefixes} from './namePrefixes.js'
import {countries} from './countries.js'
import {programs} from './programs.js'
import './ProfileForm.css';

export const ProfileForm = (props) => {
  const history = useHistory();
  const userValues = useUser(props.userId);
  const userUpsertMutation = useUserUpsertMutation(props.userId);

  useEffect(() => {
    if (userUpsertMutation.isSuccess) {
      history.push('/');
    }
  }, [userUpsertMutation.isSuccess]);

  const submit = (formValues) => {
    userUpsertMutation.mutate({
      ...userValues,
      Default_Role: userValues.Default_Role === 'New' ? 'Pending' : userValues.Default_Role,
      Prefix: formValues.Prefix,
      FirstName: formValues.FirstName,
      LastName: formValues.LastName,
      Country: formValues.Country,
      ProgramName: formValues.ProgramName,
    });
  };

  return (
    <Form i18nScopes={[...(props.i18nScopes || []), 'UserForm', 'User']} savedValues={userValues} onSubmit={submit} mutation={userUpsertMutation}>
      <Input name="FirstName" required={true}/>
      <Input name="LastName" required={true}/>
      <Select name="Prefix">
        {namePrefixes.map((value) => <Option key={value} value={value} label={value == '' ? '(none)' : ''}/>)}
      </Select>
      <Select name="Country" required={true}>
        {countries.map((value) => <Option key={value} value={value}/>)}
      </Select>
      <Input name="email" type="email" disabled={true}/>
      <Select name="ProgramName" required={true}>
        {Object.values(programs).map((value) => <Option key={value.Name} value={value.Name}/>)}
      </Select>
    </Form>
  );
};


export default ProfileForm;
