import {useParams} from 'react-router-dom';
import {DashboardScreen} from './DashboardScreen';
import {CaseDashboardIcons} from './CaseDashboardIcons';

export const CaseDashboardScreen = (props) => {
  const params = useParams();
  return (
    <DashboardScreen>
      <CaseDashboardIcons caseId={params.caseId}/>
    </DashboardScreen>
  );
};

export default CaseDashboardScreen;
