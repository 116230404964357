import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo'
import {Headline} from './Headline'
import {useAuth} from './useAuth';
import {useCases} from './useApi';
import {CaseTable} from './CaseTable';
import './MyCasesScreen.css';

export const MyCasesScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const userId = useAuth().userId;
  const cases = useCases({id_Student_Treating: userId});

  const onRowClicked = row => {
    history.push('caseDashboard/' + row.id);
  };

  return (
    <AppScreen className="MyCasesScreen">
      <Logo/>
      <Headline>{t('MyCasesScreen.headline')}</Headline>
      <div className="MyCasesTable">
        <CaseTable
          cases={cases}
          onRowClicked={onRowClicked}
        />
      </div>
    </AppScreen>
  );
};

export default MyCasesScreen;
