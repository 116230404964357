import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo'
import {Headline} from './Headline'
import {Modal} from './Modal'
import {useAuth} from './useAuth';
import {useCases} from './useApi';
import {CaseTable} from './CaseTable';
import {UnrequestCaseScreen} from './UnrequestCaseScreen';

export const MyCollaborativeCasesScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash;
  const selectedCaseId = hash ? hash.replace(/^#/, '') : null;
  const userId = useAuth().userId;
  const cases = useCases({id_Instructor_Collaborator: userId});

  const onRowClicked = row => {
    if (row.CollaborationStatus == 'Approved') {
      history.push('caseDashboard/' + row.id);
    } else {
      history.push('#' + row.id);
    }
  };

  const onRequestClosed = () => {
    history.push('#');
  };

  return (
    <AppScreen className="MyCollaborativeCasesScreen" modal={selectedCaseId && <Modal><UnrequestCaseScreen caseId={selectedCaseId} onClose={onRequestClosed}/></Modal>}>
      <Logo/>
      <Headline>{t('MyCollaborativeCasesScreen.headline')}</Headline>
      <div className="MyCollaborativeCasesTable">
        <CaseTable
          cases={cases}
          onRowClicked={onRowClicked}
        />
      </div>
    </AppScreen>
  );
};

export default MyCollaborativeCasesScreen;
