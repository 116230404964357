import {useTranslation} from 'react-i18next';
import {useContext, useState, useEffect, useRef} from 'react';
import MUISelect from 'muicss/lib/react/select';
import {useI18nScopes, useFieldReducer, useFocusNext} from './Form';
import './Select.css';

export const Select = (props) => {
  const i18nScopes = useI18nScopes().map(s => s + '.' + props.name);
  const {t} = useTranslation();

  const ref = useRef();
  const focusNext = useFocusNext(props.name);

  const [state, dispatch] = useFieldReducer({
    ...props,
    focus: () => ref.current.controlEl.focus(),
  });

  useEffect(() => {
    dispatch({
      name: 'valuesChanged',
      savedValue: props.savedValue,
      value: props.value,
    });
  }, [props.savedValue, props.value]);

  const onChange = (e) => {
    dispatch({
      name: 'valueChangedByUser',
      value: e.target.value,
    });
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(state.value);
    }
  }, [state.value]);

  return (
    <div className={'Select ' + (props.className || '')}>
      <MUISelect {...{
          ref,
          name: props.name,
          label: t([].concat(...i18nScopes.map(s => [s + '.label', s]))) + (props.required ? ' *' : ''),
          type: props.type,
          placeholder: (props.placeholder === false || props.disabled) ? undefined : (props.placeholder ? props.placeholder : t([...i18nScopes.map(s => s + '.placeholder'), 'Select.placeholder'])),
          value: state.value || '',
          required: props.required,
          disabled: props.disabled,
          onChange,
        }}>
        {props.children}
      </MUISelect>
    </div>
  );
};

export default Select;
