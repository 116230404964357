import {Route, Switch, Redirect} from 'react-router-dom';
import {useAuth, AuthStatus} from './useAuth';
import {useUser} from './useApi';
import {CreateProfileScreen} from './CreateProfileScreen';
import {ProfileScreen} from './ProfileScreen';
import {StudentDashboardScreen} from './StudentDashboardScreen';
import {InstructorDashboardScreen} from './InstructorDashboardScreen';
import {AdminDashboardScreen} from './AdminDashboardScreen';
import {AddCaseScreen} from './AddCaseScreen';
import {CaseDashboardScreen} from './CaseDashboardScreen';
import {CaseInformationScreen} from './CaseInformationScreen';
import {TTMeasurementsScreen} from './TTMeasurementsScreen'
import {WorkspaceScreen} from './WorkspaceScreen';
import {ResourcesScreen} from './ResourcesScreen';
import {CaseResourcesScreen} from './CaseResourcesScreen';
import {MyCasesScreen} from './MyCasesScreen';
import {MyCollaborativeCasesScreen} from './MyCollaborativeCasesScreen';
import {AvailableCasesScreen} from './AvailableCasesScreen';
import {AdminAllUsersScreen} from './AdminAllUsersScreen';
import {AdminEditUserScreen} from './AdminEditUserScreen';
import {ApprovalWaitScreen} from './ApprovalWaitScreen';
import {ApproveUsersScreen} from './ApproveUsersScreen';
import {ApproveCollaborationsScreen} from './ApproveCollaborationsScreen';
import {ReviewCasesScreen} from './ReviewCasesScreen';
import {N404ErrorScreen} from './N404ErrorScreen';
import {Spinner} from './Spinner';

/*
   import {CollabRequestApprovalScreen} from './CollabRequestApprovalScreen';
 */

export const AuthenticatedRoutes = () => {
  const auth = useAuth();
  const user = useUser();

  const chooseHome = () => {
    switch (user.Default_Role) {
      case 'New':
        return '/createProfile';
      case 'Pending':
        return '/approvalWait';
      case 'Student':
      case 'Treating':
      case 'Instructor':
      case 'Collaborating':
      case 'Admin':
        return '/dashboard';
    }
    throw new Error('Unknown role: ' + JSON.stringify(user.Default_Role));
  }

  const chooseDashboard = () => {
    switch (user.Default_Role) {
      case 'Student':
      case 'Treating':
        return <StudentDashboardScreen/>;
      case 'Instructor':
      case 'Collaborating':
        return <InstructorDashboardScreen/>;
      case 'Admin':
        return <AdminDashboardScreen/>;
    }
    return <N404ErrorScreen/>;
  };

  return (
    <Switch>
      <Route exact path="/"><Redirect to={chooseHome()}/></Route>
      <Route exact path="/createProfile"><CreateProfileScreen/></Route>
      <Route exact path="/profile"><ProfileScreen/></Route>
      <Route exact path="/dashboard">{chooseDashboard()}</Route>
      <Route exact path="/adminDashboard"><AdminDashboardScreen/></Route>
      <Route exact path="/instructorDashboard"><InstructorDashboardScreen/></Route>
      <Route exact path="/studentDashboard"><StudentDashboardScreen/></Route>
      <Route exact path="/addCase"><AddCaseScreen/></Route>
      <Route exact path="/caseDashboard/:caseId"><CaseDashboardScreen/></Route>
      <Route exact path="/caseInformation/:caseId"><CaseInformationScreen/></Route>
      <Route exact path="/measurements/:caseId"><TTMeasurementsScreen/></Route>
      <Route exact path="/workspace/:caseId"><WorkspaceScreen/></Route>
      <Route exact path="/resources/:caseId"><CaseResourcesScreen/></Route>
      <Route exact path="/resources"><ResourcesScreen/></Route>
      <Route exact path="/myCases"><MyCasesScreen/></Route>
      <Route exact path="/myCollaborativeCases"><MyCollaborativeCasesScreen/></Route>
      <Route exact path="/availableCases"><AvailableCasesScreen/></Route>
      <Route exact path="/adminAllUsers"><AdminAllUsersScreen/></Route>
      <Route exact path="/adminEditUser/:userId"><AdminEditUserScreen/></Route>
      <Route exact path="/approvalWait"><ApprovalWaitScreen/></Route>
      <Route exact path="/approveUsers"><ApproveUsersScreen/></Route>
      <Route exact path="/approveCollaborations"><ApproveCollaborationsScreen/></Route>
      <Route exact path="/reviewCases"><ReviewCasesScreen/></Route>
      <Route><N404ErrorScreen/></Route>
    </Switch>
  );
}


export default AuthenticatedRoutes;
