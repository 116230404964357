import {DashboardScreen} from './DashboardScreen';
import {ResourcesDashboardIcons} from './ResourcesDashboardIcons';

export const ResourcesScreen = (props) => {
  return (
    <DashboardScreen>
      <ResourcesDashboardIcons/>
    </DashboardScreen>
  );
};

export default ResourcesScreen;