import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo'
import {Headline} from './Headline'
import {useAuth} from './useAuth';
import {useCases} from './useApi';
import {CaseTable} from './CaseTable';
import {Modal} from './Modal';
import {RequestCaseScreen} from './RequestCaseScreen.js';
import './AvailableCasesScreen.css';

export const AvailableCasesScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash;
  const selectedCaseId = hash ? hash.replace(/^#/, '') : null;
  const cases = useCases({'id_Instructor_Collaborator': ''});

  const onRowClicked = row => {
    history.push('#' + row.id);
  };

  const onRequestClosed = () => {
    history.push('#');
  };

  return (
    <AppScreen className="AvailableCasesScreen" modal={selectedCaseId && <Modal><RequestCaseScreen caseId={selectedCaseId} onClose={onRequestClosed}/></Modal>}>
      <Logo/>
      <Headline>{t('AvailableCasesScreen.headline')}</Headline>
      <div className="AllCasesTable">
        <CaseTable
          cases={cases}
          onRowClicked={onRowClicked}
        />
      </div>
    </AppScreen>
  );
};

export default AvailableCasesScreen;
