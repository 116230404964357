import {DashboardScreen} from './DashboardScreen';
import {InstructorDashboardIcons} from './InstructorDashboardIcons';

export const InstructorDashboardScreen = (props) => {
  return (
    <DashboardScreen>
      <InstructorDashboardIcons/>
    </DashboardScreen>
  );
};

export default InstructorDashboardScreen;
