import './Modal.css';

export const Modal = (props) => {
  return (
    <div className={'Modal ' + (props.className || '')}>
        <div className="ModalViewport">
          <div className="ModalContent">
            {props.children}
          </div>
      </div>
    </div>
  );
}

export default Modal;
