import './Subtitle.css';

export const Subtitle = (props) => (
  <div className={'Subtitle ' + (props.className || '')}>
    <div className="systemFontRegular unselectable">
      <div>{props.children}</div>
    </div>
  </div>
);

export default Subtitle;
