import {DashboardScreen} from './DashboardScreen';
import {AdminDashboardIcons} from './AdminDashboardIcons';
import {InstructorDashboardIcons} from './InstructorDashboardIcons';

export const AdminDashboardScreen = (props) => {
  return (
    <DashboardScreen>
      <AdminDashboardIcons/>
      <InstructorDashboardIcons/>
    </DashboardScreen>
  );
};

export default AdminDashboardScreen;