import './Title.css';

export const Title = (props) => (
  <div className={'Title ' + (props.className || '')}>
    <div className="systemFontRegular unselectable">
      <div>{props.children}</div>
    </div>
  </div>
);

export default Title;
