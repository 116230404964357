import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useAuth} from './useAuth';
import {useUserFullName, useCaseInsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {Button} from './Button';
import {CaseForm} from './CaseForm';
import {openInNewTab} from './openInNewTab';
import './AddCaseScreen.css';

export const AddCaseScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const userId = useAuth().userId;
  const userFullName = useUserFullName(userId);
  const mutation = useCaseInsertMutation();

  useEffect(() => {
    if (mutation.isSuccess) {
      history.push('/caseDashboard/' + mutation.data.id);
    }
  }, [mutation.isSuccess]);

  const caseValues = {
    PT_CaseStatus: 'New',
    id_Student_Treating: userId,
    PT_TreatingClinician: userFullName,
  };

  const submit = (formValues) => {
    mutation.mutate({
      ...caseValues,
      ...formValues,
    });
  };

  return (
    <AppScreen className="AddCaseScreen">
      <Logo/>
      <Headline>{t('AddCaseScreen.headline')}</Headline>
      <Button className="VideoButton" onClick={() => openInNewTab("https://youtu.be/yQ2fguC7FEI")}>{t('AddCaseScreen.videoButton')}</Button>
      <CaseForm values={caseValues} onSubmit={submit} mutation={mutation}/>
    </AppScreen>
  );
};

export default AddCaseScreen;
