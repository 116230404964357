import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useTTMeasurements, useTTMeasurementsUpsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {Button} from './Button';
import {TTMeasurementForm} from './TTMeasurementForm';
import {openInNewTab} from './openInNewTab';
import './TTMeasurementsScreen.css';

export const TTMeasurementsScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const originalValues = useTTMeasurements(params.caseId) || {};
  const mutation = useTTMeasurementsUpsertMutation(params.caseId);
  
  useEffect(() => {
    if (mutation.isSuccess) {
      history.push('/caseDashboard/' + mutation.data.id);
    }
  }, [mutation.isSuccess]);

  const submit = (values) => {
    mutation.mutate({
      ...originalValues,
      ...values
    });
  };

  return (
    <AppScreen className="TTMeasurementsScreen" home={'/caseDashboard/' + params.caseId}>
      <Logo/>
      <Headline>{t('TTMeasurementsScreen.headline')}</Headline>
      <Button className="OpenInNewTabButton" onClick={() => openInNewTab(window.location.href)}>{t('TTMeasurementsScreen.openInNewTabButton')}</Button>
      <TTMeasurementForm values={originalValues} onSubmit={submit} mutation={mutation}/>
    </AppScreen>
  );
};

export default TTMeasurementsScreen;
