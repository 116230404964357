import {useParams} from 'react-router-dom';
import {DashboardScreen} from './DashboardScreen';
import {ResourcesDashboardIcons} from './ResourcesDashboardIcons';

export const CaseResourcesScreen = (props) => {
  const params = useParams();
  return (
    <DashboardScreen home={'/caseDashboard/' + params.caseId}>
      <ResourcesDashboardIcons/>
    </DashboardScreen>
  );
};

export default CaseResourcesScreen;
