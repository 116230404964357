import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Fragment} from 'react';
import {Headline} from './Headline';
import {DashboardIcons} from './DashboardIcons';
import {IconButton} from './IconButton';
import addUserIcon from './images/userwithplus.png';
import reviewProfilesIcon from './images/folderwithusericon.png';
import allUsersIcon from './images/lockwithsmallusericon.png';
import reviewCasesIcon from './images/stackedfolderswithcheckmark.png';
import approveCollabIcon from './images/folderencircledinarrows.png';

export const AdminDashboardIcons = (props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const buttons = [
    {
      screen: 'addUser',
      icon: addUserIcon,
    },
    {
      screen: 'approveUsers',
      icon: reviewProfilesIcon,
    },
    {
      screen: 'adminAllUsers',
      icon: allUsersIcon,
    },
    {
      screen: 'reviewCases',
      icon: reviewCasesIcon,
    },
    {
      screen: 'approveCollaborations',
      icon: approveCollabIcon,
    },
  ];

  return (
    <Fragment>
      <Headline>{t('AdminDashboardIcons.headline')}</Headline>
      <DashboardIcons>
        {buttons.map((b, i) => (
          <IconButton key={i} src={b.icon} alt={t('AdminDashboardIcons.' + b.screen + 'Alt')} onClick={() => history.push('/' + b.screen)}>
            {t('AdminDashboardIcons.' + b.screen)}
          </IconButton>
        ))}
      </DashboardIcons>
    </Fragment>
  );
};

export default AdminDashboardIcons;
