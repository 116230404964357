import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useAuth} from './useAuth';
import {useUserFullName, useCase, useCaseUpsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {CaseForm} from './CaseForm';

export const RequestCaseScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const userId = useAuth().userId;
  const userFullName = useUserFullName(userId);
  const caseId = props.caseId || params.caseId;
  const caseValues = useCase(caseId);
  const mutation = useCaseUpsertMutation(caseId);

  useEffect(() => {
    if (mutation.isSuccess) {
      props.onClose();
    }
  }, [mutation.isSuccess]);

  const submit = (formValues) => {
    mutation.mutate({
      ...caseValues,
      CollaborationStatus: 'Requested',
      id_Instructor_Collaborator: userId,
      PT_PrescribingPhysician: userFullName,
    });
  };

  const cancel = () => {
    props.onClose();
  };

  return (
    <div className="RequestCaseScreen">
      <Headline>{t('RequestCaseScreen.headline')}</Headline>
      <CaseForm i18nScopes={['RequestCaseScreen']} values={caseValues} onSubmit={submit} onCancel={cancel} mutation={mutation}/>
    </div>
  );
};

export default RequestCaseScreen;
