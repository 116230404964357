import {useContext, useEffect} from 'react';
import {WorkspaceContext} from './WorkspaceContext';
import {WorkspaceInstance} from './WorkspaceInstance';

export const Workspace = ({caseId}) => {
  const context = useContext(WorkspaceContext);
  useEffect(() => {
    return () => {
      context.workspaceDestroyed();
    };
  }, []);
  return (
    <div className="Workspace">
      <WorkspaceInstance caseId={caseId}/>
    </div>
  );
};

export default Workspace;
