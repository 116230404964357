import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo'
import {Headline} from './Headline'
import {useAuth} from './useAuth';
import {useUsers} from './useApi';
import {UserTable} from './UserTable';
import {Modal} from './Modal';
import {AdminEditUserScreen} from './AdminEditUserScreen';
import './ApproveUsersScreen.css';

export const ApproveUsersScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const hash = location.hash;
  const selectedUserId = hash ? hash.replace(/^#/, '') : null;
  const users = useUsers({'Default_Role': 'Pending'});

  const onRowClicked = row => {
    history.push('#' + row.id);
  };

  const onEditClosed = () => {
    history.push('#');
  };

  return (
    <AppScreen className="ApproveUsersScreen" modal={selectedUserId && <Modal><AdminEditUserScreen userId={selectedUserId} onClose={onEditClosed}/></Modal>}>
      <Logo/>
      <Headline>{t('ApproveUsersScreen.headline')}</Headline>
      <div className="AllUsersTable">
        <UserTable
          users={users}
          onRowClicked={onRowClicked}
        />
      </div>
    </AppScreen>
  );
};

export default ApproveUsersScreen;
