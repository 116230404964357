export const intervention = {
  "HP": {
    "PercentRedux": "1",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "HD": {
    "PercentRedux": "2",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "TF": {
    "PercentRedux": "3",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "KD": {
    "PercentRedux": "4",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "TT": {
    "PercentRedux": "5",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "SY": {
    "PercentRedux": "6",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "CP": {
    "PercentRedux": "7",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "TM": {
    "PercentRedux": "8",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "PF": {
    "PercentRedux": "9",
    "LocationCode": "LR",
    "OXPXCode": "PX",
  },
  "FQ": {
    "PercentRedux": "10",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "SD": {
    "PercentRedux": "11",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "TH": {
    "PercentRedux": "12",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "ED": {
    "PercentRedux": "13",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "TR": {
    "PercentRedux": "14",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "WD": {
    "PercentRedux": "15",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "TC": {
    "PercentRedux": "16",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
  "PH": {
    "PercentRedux": "17",
    "LocationCode": "UP",
    "OXPXCode": "PX",
  },
};

export default intervention;
