import ReactDOM from 'react-dom';
import {Top} from './Top';
import {WorkspaceContextProvider} from './WorkspaceContext';
import './i18n';
import 'console-browserify';

ReactDOM.render(
  <WorkspaceContextProvider>
    <Top/>
  </WorkspaceContextProvider>,
  document.getElementById('root'));
