import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {useAuth} from './useAuth.js'
import {AppScreen} from './AppScreen';
import {Title} from './Title';
import {Logo} from './Logo';
import {Subtitle} from './Subtitle';
import {Button} from './Button';
import './ApprovalWaitScreen.css'

export const ApprovalWaitScreen = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const auth = useAuth();
  return (
    <AppScreen className="ApprovalWaitScreen">
      <Logo/>
      <Title>{t('ApprovalWaitScreen.title')}</Title>
      <Subtitle>{t('ApprovalWaitScreen.subtitle')}</Subtitle>
      <Button onClick={() => history.push('/profile')}>{t('ApprovalWaitScreen.editProfileButton')}</Button>
    </AppScreen>
  );
}

export default ApprovalWaitScreen;
