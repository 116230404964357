import {useParams} from 'react-router-dom';
import Workspace from './Workspace';

export const WorkspaceScreen = () => {
  const { caseId } = useParams();
  return (
    <Workspace caseId={caseId}/>
  );
};

export default WorkspaceScreen;
