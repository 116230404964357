import {useEffect} from 'react';

export const useBeforeUnload = ({when, message}) => {
  useEffect(() => {
    if (when) {
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        const text = message instanceof Function ? message() : message;
        event.returnValue = text;
        return text;
      }
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      }
    }
  }, [when, message]);
};

export default useBeforeUnload;
