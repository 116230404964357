import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {AppScreen} from './AppScreen';
import {Title} from './Title';
import {Button} from './Button';

export const N404ErrorScreen = () => {
  const {t} = useTranslation();
  const history = useHistory();
  return (
    <AppScreen className="N404ErrorScreen">
      <Title>{t('N404ErrorScreen.title')}</Title>
      <Button onClick={() => history.push('/')}>{t('N404ErrorScreen.homeButton')}</Button>
    </AppScreen>
  );
};

export default N404ErrorScreen;
