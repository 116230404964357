import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useTable} from 'react-table'
import './DataTable.css';

export const DataTable = (props) => {
  const {t} = useTranslation();

  const i18nScopes = [...(props.i18nScopes || []), 'DataTable'];

  const columns = useMemo(
    () => props.columns.map(c => ({
      Header: t(i18nScopes.map(s => s + '.' + (c.id || c.accessor))),
      ...c,
    })),
    [props.i18nScopes, props.columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: props.data,
  });

  const getGeneralRowProps = (row) => {
    const generalRowProps = {};
    if (props.onRowClicked) {
      generalRowProps.onClick = () => props.onRowClicked(row.original);
    }
    return generalRowProps;
  };

  return (
    <table className="DataTable mui-table mui-table--bordered" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...getGeneralRowProps(row)} {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
