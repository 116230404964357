import {useTranslation} from 'react-i18next';
import {useAuth} from './useAuth.js'
import {AppScreen} from './AppScreen';
import {Title} from './Title';
import {Logo} from './Logo';
import {Subtitle} from './Subtitle';
import {Button} from './Button';
import './WelcomeScreen.css'

export const WelcomeScreen = () => {
  const {t} = useTranslation();
  const auth = useAuth();
  return (
    <AppScreen className="WelcomeScreen">
      <Title>{t('WelcomeScreen.title')}</Title>
      <Logo/>
      <Subtitle>{t('WelcomeScreen.productName')}</Subtitle>
      <Button onClick={() => auth.initiateLogin()}>{t('WelcomeScreen.loginButton')}</Button>
    </AppScreen>
  );
}

export default WelcomeScreen;
