import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {Fragment} from 'react';
import {Headline} from './Headline';
import {DashboardIcons} from './DashboardIcons';
import {IconButton} from './IconButton';
import addCaseIcon from './images/folderwithplus.png';
import myCasesIcon from './images/stackedfolders.png';
import myCollabCasesIcon from './images/folderwithusericon.png';
import availCasesIcon from './images/stackedfolders.png';
import resourcesIcon from './images/pencil.png';

export const InstructorDashboardIcons = (props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const buttons = [
    {
      screen: 'addCase',
      icon: addCaseIcon,
    },
    {
      screen: 'myCases',
      icon: myCasesIcon,
    },
    {
      screen: 'resources',
      icon: resourcesIcon
    },
    {
      screen: 'availableCases',
      icon: availCasesIcon,
    },
    {
      screen: 'myCollaborativeCases',
      icon: myCollabCasesIcon,
    },
  ];

  return (
    <Fragment>
      <Headline>{t('InstructorDashboardIcons.headline')}</Headline>
      <DashboardIcons>
        {buttons.map((b, i) => (
          <IconButton key={i} src={b.icon} alt={t('InstructorDashboardIcons.' + b.screen + 'Alt')} onClick={() => history.push('/' + b.screen)}>
            {t('InstructorDashboardIcons.' + b.screen)}
          </IconButton>
        ))}
      </DashboardIcons>
    </Fragment>
  );
};

export default InstructorDashboardIcons;
