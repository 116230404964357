import {Switch, Route, Redirect, useHistory} from 'react-router-dom';
import {createPath} from 'history';
import {useEffect} from 'react';
import {useAuth, AuthStatus, AUTH_CALLBACK_PATH, POST_LOGOUT_PATH} from './useAuth';
import {LoggedOutScreen} from './LoggedOutScreen';
import {Spinner} from './Spinner';
import {AuthenticatedRoutes} from './AuthenticatedRoutes';
import {WelcomeScreen} from './WelcomeScreen';
import './App.css';

const hasSavedLocation = () => {
  const savedLocation = localStorage.getItem('savedLocation');
  return savedLocation && savedLocation !== '';
};

const popSavedLocation = () => {
  const savedLocation = localStorage.getItem('savedLocation');
  localStorage.removeItem('savedLocation');
  return savedLocation && savedLocation !== '' ? JSON.parse(savedLocation) : null;
};

const maybeSaveLocation = history => {
  if (!hasSavedLocation()) {
    localStorage.setItem('savedLocation', JSON.stringify(history.location));
  }
}

const AuthCallback = () => {
  const auth = useAuth();

  if (auth.status == AuthStatus.LOGGED_IN) {
    const savedLocation = popSavedLocation();
    if (savedLocation !== null) {
      return <Redirect to={savedLocation}/>;
    }
  }

  return <Redirect to="/"/>
};

const Login = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.status == AuthStatus.LOGGED_OUT) {
      auth.initiateLogin();
    }
  }, []);
  return auth.userId ? <Redirect to="/"/> : <Spinner/>;
};

const Logout = () => {
  const auth = useAuth();
  useEffect(() => {
    if (auth.status != AuthStatus.LOGGING_OUT) {
      auth.initiateLogout();
    }
  }, []);
  return <Spinner/>;
}

const AutoLoginRoutes = () => {
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (auth.status == AuthStatus.LOGGED_OUT) {
      maybeSaveLocation(history);
      auth.initiateSilentLogin();
    } else if (auth.status == AuthStatus.FLOW_CANCELLED) {
      auth.initiateSilentLogin();
    } else if (auth.status == AuthStatus.FORGOT_PASSWORD) {
      auth.initiatePasswordReset();
    } else if (auth.status == AuthStatus.SILENT_LOGIN_FAILED && hasSavedLocation()) {
      auth.initiateLogin();
    } else if (auth.status == AuthStatus.PASSWORD_WAS_RESET) {
      const savedLocation = popSavedLocation();
      auth.initiateLogout(savedLocation ? createPath(savedLocation) : null);
    }
  }, []);

  if (auth.status == AuthStatus.LOGGED_IN) {
    return (
      <Switch>
        <Route exact path="/welcome"><Redirect to="/"/></Route>
        <Route exact path={POST_LOGOUT_PATH}><Redirect to="/"/></Route>
        <Route><AuthenticatedRoutes/></Route>
      </Switch>
    );
  }

  if (auth.status == AuthStatus.SILENT_LOGIN_FAILED && !hasSavedLocation()) {
    return (
      <Switch>
        <Route exact path="/welcome"><WelcomeScreen/></Route>
        <Route exact path={POST_LOGOUT_PATH}><LoggedOutScreen/></Route>
        <Route><Redirect to="/welcome"/></Route>
      </Switch>
    );
  }

  return <Spinner/>;
};

export const App = () => {
  const auth = useAuth();
  return (
    <div className="App">
      {
        auth.status == AuthStatus.INITIALIZING
        ? <Spinner/>
        : (
          <Switch>
            <Route exact path={AUTH_CALLBACK_PATH}><AuthCallback/></Route>
            <Route exact path="/login"><Login/></Route>
            <Route exact path="/logout"><Logout/></Route>
            {auth.status == AuthStatus.LOGGED_OUT ? <Route exact path={POST_LOGOUT_PATH}><LoggedOutScreen/></Route> : null}
            <Route><AutoLoginRoutes/></Route>
          </Switch>
        )
      }
    </div>
  );
};

export default App;
