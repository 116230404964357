import {useTranslation} from 'react-i18next';
import {Children, Fragment} from 'react';
import {Form} from './Form';
import {Input} from './Input';
import {Select} from './Select';
import {Option} from './Option';
import {useAuth} from './useAuth';
import {countries} from './countries'
import {laterality} from './laterality';
import {intervention} from './intervention';
import {collaborationStatus} from './collaborationStatus';

export const CaseForm = (props) => {
  const {t} = useTranslation();
  const userId = useAuth().userId;

  const formProps = {
    ...props,
    i18nScopes: [...(props.i18nScopes || []), 'CaseForm', 'Case'],
  };

  const isMyCase = props.values.id_Student_Treating == userId;
  const myCaseProps = {
    required: isMyCase,
    disabled: !isMyCase,
  };

  return (
    <Form {...formProps}>
      <Input name="PT_FirstName" {...myCaseProps}/>
      <Input name="PT_LastName" {...myCaseProps}/>
      <Select name="PT_Country" {...myCaseProps}>
        {countries.map((value) => <Option key={value} value={value}/>)}
      </Select>
      <Input name="PT_Diagnosis" {...myCaseProps}/>
      <Input name="PT_DOB" {...myCaseProps}/>
      <Select name="PT_Laterality" {...myCaseProps}>
        {laterality.map((value) => <Option key={value} label={t('lateralityName.' + value, value)} value={value}/>)}
      </Select>
      <Select name="PT_Intervention" {...myCaseProps}>
        {Object.keys(intervention).map((value) => <Option key={value} label={t('interventionName.' + value, value)} value={value}/>)}
      </Select>
      <Input name="PT_DateofOnset" {...myCaseProps}/>
      <Input name="PT_EvalScanDate" {...myCaseProps}/>
      <Input name="PT_CaseNotes" {...myCaseProps}/>
      <Input name="CaseReference" {...myCaseProps}/>
      <Input name="PT_TreatingClinician" disabled={true}/>
      <Input name="PT_PrescribingPhysician" disabled={true}/>
      {props.values.id_Instructor_Collaborator && props.values.id_Instructor_Collaborator != '' &&
       <Select name="CollaborationStatus" disabled={!props.admin}>
         {collaborationStatus.map((value) => <Option key={value} label={t('collaborationStatusName.' + value, value)} value={value}/>)}
       </Select>
      }
    </Form>
  );
};


export default CaseForm;
