import {AppScreen} from './AppScreen';
import {Logo} from './Logo'

export const DashboardScreen = (props) => {
  return (
    <AppScreen className="DashboardScreen" home={props.home}>
      <Logo/>
      {props.children}
    </AppScreen>
  );
};

export default DashboardScreen;
