import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Children} from 'react';
import {Form} from './Form';
import {Input} from './Input';
import {Select} from './Select';
import {Option} from './Option';
import {useUser, useUserUpsertMutation} from './useApi';
import {namePrefixes} from './namePrefixes.js'
import {countries} from './countries.js'
import {programs} from './programs.js'
import {roles} from './roles.js'

export const UserForm = (props) => (
  <Form i18nScopes={[...(props.i18nScopes || []), 'UserForm', 'User']} {...props}>
    <Input name="FirstName" disabled={true}/>
    <Input name="LastName" disabled={true}/>
    <Select name="Prefix" disabled={true}>
      {namePrefixes.map((value) => <Option key={value} value={value} label={value == '' ? '(none)' : ''}/>)}
    </Select>
    <Select name="Country" disabled={true}>
      {countries.map((value) => <Option key={value} value={value}/>)}
    </Select>
    <Input name="email" type="email" disabled={true}/>
    <Select name="ProgramName" disabled={true}>
      {Object.values(programs).map((value) => <Option key={value.Name} value={value.Name}/>)}
    </Select>
    <Select name="Default_Role">
      {Object.values(roles).map((value) => <Option key={value} value={value}/>)}
    </Select>
  </Form>
);

export default UserForm;
