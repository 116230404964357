import {useTranslation} from 'react-i18next';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {ProfileForm} from './ProfileForm';

export const CreateProfileScreen = (props) => {
  const {t} = useTranslation();

  return (
    <AppScreen className="CreateProfileScreen">
      <Logo/>
      <Headline>{t('CreateProfileScreen.headline')}</Headline>
      <ProfileForm userId={props.userId}/>
    </AppScreen>
  );
};

export default CreateProfileScreen;
