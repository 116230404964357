export const programs = {
  "000": {
    "Name": "Operation Namaste",
    "City": "Lake Placid",
    "State_Province": "NY",
    "Country": "United States",
  },
  "001": {
    "Name": "George Brown College",
    "City": "Toronto",
    "State_Province": "ON",
    "Country": "Canada",
  },
  "002": {
    "Name": "University of Hartford",
    "City": "West Hartford",
    "State_Province": "CT",
    "Country": "United States",
  },
};

export default programs;
