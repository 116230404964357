import {useTranslation} from 'react-i18next';
import {Image} from './Image';
import logo from './images/onlogo.png';
import './Logo.css';

export const Logo = () => {
  const {t} = useTranslation();
  return (
    <Image className="Logo" src={logo} alt={t('logoAlt')}/>
  );
};

export default Logo;
