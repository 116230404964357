import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {useUser, useUserUpsertMutation} from './useApi';
import {useEffect} from 'react';
import {AppScreen} from './AppScreen';
import {Logo} from './Logo';
import {Headline} from './Headline';
import {UserForm} from './UserForm';

export const AdminEditUserScreen = (props) => {
  const {t} = useTranslation();
  const history = useHistory();
  const params = useParams();
  const userId = props.userId || params.userId;
  const userValues = useUser(userId);
  const mutation = useUserUpsertMutation(userId);

  useEffect(() => {
    if (mutation.isSuccess) {
      props.onClose();
    }
  }, [mutation.isSuccess]);

  const submit = (formValues) => {
    mutation.mutate({
      ...userValues,
      ...formValues
    });
  };

  const cancel = () => {
    props.onClose();
  };

  return (
    <div className="AdminEditUserScreen">
      <Headline>{t('AdminEditUserScreen.headline')}</Headline>
      <UserForm values={userValues} onSubmit={submit} onCancel={cancel} mutation={mutation}/>
    </div>
  );
};

export default AdminEditUserScreen;
