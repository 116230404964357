// This module follows the pattern documented at https://usehooks.com/useAuth/
import {createContext, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useQuery} from 'react-query';
import {PublicClientApplication, InteractionRequiredAuthError} from '@azure/msal-browser';

const b2cAuthorityDomain = "operationnamasteccn.b2clogin.com";

function buildAuthority(policyName) {
  return "https://" + b2cAuthorityDomain + "/operationnamasteccn.onmicrosoft.com/" + policyName;
};

const b2cPolicyNames = {
  signUpSignIn: "B2C_1_signup_signin",
  resetPassword: "B2C_1_resetpassword",
};

const b2cAuthorities = Object.entries(b2cPolicyNames).reduce((a, [k, v]) => (a[k] = buildAuthority(v), a), {});

const scopes = [
  "https://operationnamasteccn.onmicrosoft.com/e60e10e8-6ba9-4b97-850a-ff3cd0591a02/site"
];

const loginRequest = {
  scopes: ["openid", ...scopes],
};
const tokenRequest = {
  scopes: scopes,
};

export const AUTH_CALLBACK_PATH = '/authcallback';
export const POST_LOGOUT_PATH = '/loggedOut';

const realMsal = new PublicClientApplication({
  auth: {
    clientId: "e60e10e8-6ba9-4b97-850a-ff3cd0591a02",
    authority: b2cAuthorities.signUpSignIn,
    knownAuthorities: [b2cAuthorityDomain],
    redirectUri: AUTH_CALLBACK_PATH,
    postLogoutRedirectUri: POST_LOGOUT_PATH,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  }
});

function makeEnum(names) {
  return Object.freeze(names.reduce((o, n) => ({...o, [n]: new String(n)}), {}));
}

export const AuthStatus = makeEnum([
  'INITIALIZING',
  'SILENT_LOGIN_FAILED',
  'LOGGING_IN',
  'LOGGED_IN',
  'LOGGING_OUT',
  'LOGGED_OUT',
  'ACQUIRING_ACCESS_TOKEN',
  'FORGOT_PASSWORD',
  'RESETTING_PASSWORD',
  'PASSWORD_WAS_RESET',
  'FLOW_CANCELLED',
]);

export const MockMsal = {
  msalRedirectPromise: (async () => ({}))(),
  getAllAccounts: () => {
    return [{localAccountId: 'MOCK_USER_ID', idTokenClaims: {tfp: b2cPolicyNames.signUpSignIn}}]
  },
  acquireTokenSilent: async (request) => ({accessToken: 'MOCK_ACCESS_TOKEN'}),
  acquireTokenRedirect: (request) => {},
  loginRedirect: (request) => {},
  logoutRedirect: (request) => {},
};

const realMsalRedirectPromise = realMsal.handleRedirectPromise();
const getAuthState = async (msal, msalRedirectPromise) => {
  let response;
  try {
    response = await msalRedirectPromise;
  } catch (error) {
    if (error.errorMessage.indexOf("AADB2C90118") > -1) {
      return {
        status: AuthStatus.FORGOT_PASSWORD,
      };
    } else if (error.errorMessage.indexOf("AADB2C90077") > -1) {
      return {
        status: AuthStatus.SILENT_LOGIN_FAILED,
      };
    } else if (error.errorMessage.indexOf("AADB2C90091") > -1) {
      // "The user has cancelled entering self-asserted information"
      // Occurs at least when cancelling a "forgot password" sequence.
      return {
        status: AuthStatus.FLOW_CANCELLED,
      };
    }
    console.warn({getAuthState: error});
    throw error;
  }

  const currentAccounts = msal.getAllAccounts();
  if (currentAccounts.length === 0) {
    return {
      status: AuthStatus.LOGGED_OUT,
    };
  }
  for (const account of currentAccounts) {
    const policy = account.idTokenClaims['tfp'];
    if (policy === b2cPolicyNames.resetPassword) {
      return {
        status: AuthStatus.PASSWORD_WAS_RESET,
      };
    } else if (policy != b2cPolicyNames.signUpSignIn) {
      throw new Error("Unrecognized policy: " + policy);
    }
  }

  if (currentAccounts.length > 1) {
    console.warn({currentAccounts});
    throw new Error("Multiple accounts detected.");
  }

  return {
    status: AuthStatus.LOGGED_IN,
    activeAccount: currentAccounts[0],
  };
}

function makeTokenRequest(account) {
  return {
    ...tokenRequest,
    account,
  };
}

async function acquireTokenSilent(msal, account) {
  const request = makeTokenRequest(account);
  let response;
  try {
    response = await msal.acquireTokenSilent(request);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      return false;
    }
    throw error;
  }

  if (!response) {
    throw new Error("null access token response");
  }

  const token = response.accessToken;
  // In case the response from B2C server has an empty accessToken field
  // throw an error to initiate token acquisition
  if (!token || token === "") {
    return false;
  }

  return token;
}

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function AuthProvider({ children, mockMsal }) {
  const auth = useAuthProvider(mockMsal);
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useAuthProvider(mockMsal) {
  const history = useHistory();
  const msal = mockMsal || realMsal;

  const [authState, setAuthState] = useState({
    status: AuthStatus.INITIALIZING,
  });

  const authQuery = useQuery('auth', async () => getAuthState(msal, mockMsal ? mockMsal.msalRedirectPromise : realMsalRedirectPromise), {
    enabled: authState.status == AuthStatus.INITIALIZING,
  });
  useEffect(() => {
    if (authQuery.isSuccess) {
      setAuthState(authQuery.data);
    }
  }, [authQuery.status]);

  const initiateAction = (name, legalState, action) => {
    if (!legalState(authState)) {
      throw new Error(`Illegal state to initiate ${name}: ${authState.status}`);
    }
    setAuthState((authState) => {
      if (!legalState(authState)) {
        return authState;
      }
      return action(authState);
    });
  };

  const initiateSilentLogin = async () => {
    initiateAction("silent login", (authState) => {
      return authState.status == AuthStatus.LOGGED_OUT || authState.status == AuthStatus.FLOW_CANCELLED;
    }, (authState) => {
      msal.loginRedirect({...loginRequest, prompt:'none'});
      return {
        status: AuthStatus.LOGGING_IN,
      };
    });
  };

  const initiateLogin = () => {
    initiateAction("login", (authState) => {
      switch (authState.status) {
        case AuthStatus.SILENT_LOGIN_FAILED:
        case AuthStatus.LOGGED_OUT:
        case AuthStatus.FORGOT_PASSWORD:
        case AuthStatus.PASSWORD_WAS_RESET:
        case AuthStatus.FLOW_CANCELLED:
          return true;
          break;
      }
      return false;
    }, (authState) => {
      msal.loginRedirect(loginRequest);
      return {
        status: AuthStatus.LOGGING_IN,
      };
    });
  };

  const initiateLogout = (postLogoutPath) => {
    initiateAction("logout", (authState) => {
      return authState.status !== AuthStatus.LOGGING_OUT;
    }, (authState) => {
      localStorage.clear();
      msal.logoutRedirect({
        postLogoutRedirectUri: postLogoutPath || POST_LOGOUT_PATH,
      });
      return {
        ...authState,
        status: AuthStatus.LOGGING_OUT,
      };
    });
  };

  const initiatePasswordReset = () => {
    initiateAction("password reset", (authState) => {
      switch (authState.status) {
        case AuthStatus.LOGGING_OUT:
        case AuthStatus.RESETTING_PASSWORD:
          return false;
      }
      return true;
    }, (authState) => {
      msal.loginRedirect({authority: b2cAuthorities.resetPassword});
      return {
        ...authState,
        status: AuthStatus.RESETTING_PASSWORD,
      };
    });
  };

  const getAccessToken = async () => {
    const legalState = (authState) => {
      return authState.status === AuthStatus.LOGGED_IN;
    };
    if (!legalState(authState)) {
      return false;
    }
    const accessToken = await acquireTokenSilent(msal, authState.activeAccount);
    if (!accessToken) {
      setAuthState((authState) => {
        if (!legalState(authState)) {
          return authState;
        }
        msal.acquireTokenRedirect(makeTokenRequest(authState.activeAccount));
        return {
          ...authState,
          status: AuthStatus.ACQUIRING_ACCESS_TOKEN,
        };
      });
    }
    return accessToken;
  }

  return {
    status: authState.status,
    userId: authState.activeAccount?.localAccountId,
    initiateSilentLogin,
    initiateLogin,
    initiateLogout,
    initiatePasswordReset,
    getAccessToken,
  };
}
