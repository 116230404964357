import {useTranslation} from 'react-i18next';
import {DataTable} from './DataTable';

export const UserTable = (props) => {
  const {t} = useTranslation();
  const users = props.users;

  const columns = [
    {
      accessor: 'Prefix',
    },
    {
      accessor: 'FirstName',
    },
    {
      accessor: 'LastName',
    },
    {
      accessor: 'Country',
    },
    {
      accessor: 'ProgramName',
    },
    {
      accessor: 'Default_Role',
      Cell: ({value}) => t('roleName.' + value, value),
    },
  ].filter(c => (!props.include || props.include.includes(c.id || c.accessor))
           && (!props.exclude || !props.exclude.includes(c.id || c.accessor)));

  return (
    <DataTable
      i18nScopes={[...(props.i18nScopes || []), 'UserTable', 'User']}
      columns={columns}
      data={users}
      onRowClicked={props.onRowClicked}
    />
  );
};

export default UserTable;
